// eslint-disable-next-line
export default (CURRENT_ENV, country) => ({
  config: 'mga',

  /**
   * Hide the limit step in sign up flow
   */
  hideLimitStep: true,

  /**
   * Override country in signup
   */
  overrideSignUpCountryMap: {
    AT: 'LI',
  },
});
